<template>
  <div>
    <van-nav-bar
      title="帮扶措施"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="basicBox">
      <div
        class="codeNum"
        v-for="(item, index) in typeArr"
        :key="index"
        @click="
          () => {
            supportingDetailsRoute(item);
          }
        "
      >
        <div>{{ item.typeName }}</div>
        <div><van-icon name="arrow" /></div>
      </div>
    </div>
    <div
      class="opinion"
      @click="
        () => {
          router('opinion');
        }
      "
    >
      意见及诉求
    </div>
  </div>
</template>

<script>
import { dividendPolicySearch } from "@/api/atp.js";
export default {
  data() {
    return {
      typeArr: [],
      typeInfoArr: [],
      infoArr: [],
    };
  },
  created() {
    var householderId = JSON.parse(
      sessionStorage.getItem("postForm")
    ).householderId;
    var tenantCode = JSON.parse(sessionStorage.getItem("users")).tenantCode;
    dividendPolicySearch({
      householderId: householderId,
      tenantCode: tenantCode,
    }).then((res) => {
      console.log(res);
      this.infoArr = res.data;
      for (var i in res.data) {
        if (this.typeArr.indexOf(res.data[i].projectCategory) == -1) {
          this.typeArr.push({
            typeName: this.common.gettypeListLabelFn(
              "projectCategoryList",
              res.data[i].projectCategory
            ),
            typeId: res.data[i].projectCategory,
          });
        }
      }
      console.log(this.typeArr);
    });
  },
  methods: {
    router(path, data = {}) {
      console.log(data);
      this.$router.push({ path: "/" + path, query: data });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    supportingDetailsRoute(type) {
      this.typeInfoArr = [];
      for (var i in this.infoArr) {
        if (this.infoArr[i].projectCategory == type.typeId) {
          this.typeInfoArr.push(this.infoArr[i]);
        }
      }
      this.router("measureDetails", { infoData: this.typeInfoArr, type: type });
    },
  },
};
</script>

<style scoped>
.opinion {
  width: 100%;
  height: 0.4rem;
  line-height: 0.4rem;
  background-color: #f0ffff;
  color: #1e90ff;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
}
/* vant 导航栏自适应 */
.van-nav-bar {
  height: 0.46rem;
}
>>> .van-nav-bar__content {
  height: 100%;
}
>>> .van-nav-bar__title {
  height: 100%;
  line-height: 0.46rem;
  font-size: 0.16rem;
}
>>> .van-icon-arrow-left,
>>> .van-nav-bar__text {
  font-size: 0.14rem;
}
.basicBox {
  margin-bottom: 0.5rem;
}
.basicBox > div {
  border-bottom: 1px solid #eee;
  width: 94%;
  height: 0.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.basicBox > div > div {
  line-height: 0.5rem;
}
</style>